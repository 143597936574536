.servicos__produtos{
	display: grid;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	margin-bottom: 40px;

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
	}

	.servico{
		background: var(--bgServicoProdutoThumb, #fff);
		perspective: 3px;
		position: relative;
		box-shadow: 0 0 5px rgba(#000,.4);
		font-size: toRem(13);
		color: var(--colorServicoProdutoThumb, #747474);
		width: 100%;
		max-width: 270px;
		margin-left: auto;
		margin-right: auto;
		text-align: left;

		a:hover{
			text-decoration: none;
		}

		&:hover{
			--w: 100%;
		}
	}

	.servico__content{
		padding: 15px;
		color: var(--colorServicoProdutoThumb, #747474);

		&.clone{
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background: $laranja;
			overflow: hidden;
			perspective: 3px;
			clip-path: circle(var(--w, 0) at 50% 50%);
			transition: all 0.4s linear;

			&:before{
				content: '';
				width: calc(100% - 30px);
				padding-bottom: calc(100% - 30px);
				position: absolute;
				left: 50%;
				top: 15px;
				background: darken($laranja, 5%);
				mask-image: var(--mask);
				mask-position: center center;
				mask-size: 100% 100%;
				mask-repeat: no-repeat;
				z-index: -2;
			}

			&:after{
				content:'';
				width: calc(100% - 30px);
				height: 100%;
				position: absolute;
				left: 15px;
				top: 0;
				border-top: 4px solid #FFF;
				border-bottom: 4px solid #FFF;
			}

			& > * {
				color: #FFF;
			}

			.servico__icon{
				background: #FFF;
			}
		}
	}

	.servico__title{
		margin-bottom: 10px;
		font-size: toRem(14);
		font-family: 'montserratbold', sans-serif;
		color: $laranja;
	}

	.servico__icon{
		width: 80px;
		height: 80px;
		background: #000;
		margin-bottom: 10px;
		mask-image: var(--mask);
		mask-position: center center;
		mask-size: 100% 100%;
	}
}
