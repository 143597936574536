.btn-pill{
	border-radius: 2rem;
}

.btn-ripple{
	overflow: hidden;
	position: relative;

	& * {
		pointer-events: none;
	}

	.ripple{
		position: absolute;
		width:100%;
		height: 100%;
		background: currentColor;
		opacity: 0.6;
		clip-path: circle(15px at var(--x, 50%) var(--y, 50%));
		left: 0;
		top: 0;
		animation: ripple 0.6s linear;
	}
}

@keyframes ripple {
	0% {
		clip-path: circle(15px at var(--x, 50%) var(--y, 50%));
		opacity: 0.6;
	}

	100% {
		clip-path: circle(150% at var(--x, 50%) var(--y, 50%));
		opacity: 0;
	}
}

.btn-laranja{
	@include button-variant($laranja,$laranja);
	color: #FFF;
}

.btn-float{
	width: 48px;
	height: 48px;
	padding: 0 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.btn-voltar{
	width: 100%;
	max-width: 200px;
}

.btn-outline-laranja{
	border: 2px solid $laranja;
	color: $laranja;

	&:hover{
		color: #FFF;
		background: $laranja;
	}
}

.btn__whatsapp{
	position: fixed;
	left: 15px;
	bottom: 15px;
	background: #01AB4C;
	z-index: 999;
	color: #FFF;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
	font-size: 40px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);

	&:hover{
		color: #FFF;
		animation: rubberBand 1s linear;
	}

	&,
	.btn__wrapper{
		display: flex;
		align-items: center;
		justify-content: center;
		width: toEm(60,40);
		height: toEm(60,40);
		border-radius: 50%;
	}

	.btn__wrapper{
		overflow: hidden;
	}

	svg{
		width: 1em;
		height: 1em;
		fill: currentColor;
		filter: drop-shadow(1px 1px #01883c) drop-shadow(2px 2px #01883c) drop-shadow(3px 3px #01883c) drop-shadow(4px 4px #01883c) drop-shadow(5px 5px #01883c) drop-shadow(6px 6px #01883c) drop-shadow(7px 7px #01883c) drop-shadow(8px 8px #01883c) drop-shadow(9px 9px #01883c) drop-shadow(10px 10px #01883c) drop-shadow(11px 11px #01883c) drop-shadow(12px 12px #01883c) drop-shadow(13px 13px #01883c) drop-shadow(14px 14px #01883c) drop-shadow(15px 15px #01883c) drop-shadow(16px 16px #01883c) drop-shadow(17px 17px #01883c) drop-shadow(18px 18px #01883c) drop-shadow(19px 19px #01883c) drop-shadow(20px 20px #01883c) drop-shadow(21px 21px #01883c) drop-shadow(22px 22px #01883c) drop-shadow(23px 23px #01883c) drop-shadow(24px 24px #01883c) drop-shadow(25px 25px #01883c) drop-shadow(26px 26px #01883c) drop-shadow(27px 27px #01883c) drop-shadow(28px 28px #01883c) drop-shadow(29px 29px #01883c) drop-shadow(30px 30px #01883c) drop-shadow(31px 31px #01883c) drop-shadow(32px 32px #01883c) drop-shadow(33px 33px #01883c) drop-shadow(34px 34px #01883c) drop-shadow(35px 35px #01883c) drop-shadow(36px 36px #01883c) drop-shadow(37px 37px #01883c) drop-shadow(38px 38px #01883c) drop-shadow(39px 39px #01883c) drop-shadow(40px 40px #01883c);
	}
}
