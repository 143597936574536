.clientes{
	display: grid;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	margin-bottom: 40px;

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(auto-fit, minmax(172px, 1fr));
	}

	@include media-breakpoint-only(sm) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include media-breakpoint-down(xs) {
		grid-template-columns: repeat(2, 1fr);
	}

	.cliente{
		box-shadow: 0 0 4px rgba(#000,.4);
		background: #FFF;
		padding: 10px;
		min-height: 120px;
		transition: all 0.4s linear;
		animation-fill-mode: none !important;

		&,
		a{
			display: flex;
		}

		a{
			width: 100%;
			align-items: center;
			justify-content: center;
		}

		&:hover{
			position: relative;
			z-index: 3;
			box-shadow: 0 0 10px rgba(#000,.5);
			transform: scale(1.05);
		}
	}
}
