.creditos{
	background: #e67d17;
	color: #FFF;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: toRem(12);

	a{
		color: inherit;

		&:hover{
			color: #232323;
			text-decoration: none;
		}
	}

	svg{
		fill: currentcolor;
		width: toEm(44,12);
		height: 2em;
		vertical-align: middle;
	}
}

@include media-breakpoint-up(md) {
	.creditos{
		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

@include media-breakpoint-down(sm) {
	.creditos{
		text-align: center;
	}

	.autor{
		margin-top: 10px;
	}
}
