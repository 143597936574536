.topo__faixa{
	font-size: toRem(12);

	.container{
		padding-top: 10px;
		padding-bottom: 10px;
		background: var(--bgFaixaTopo, #323232);
		color: var(--colorFaixaTopo, #FFF);
	}

	.contato__topo,
	.acessibilidade,
	.acessibilidade__fonts,
	.acessibilidade__contrast{
		display: flex;
	}

	.md__radio__icon + .md__radio__icon{
		margin-left: 10px;
	}
}

.contato__topo:before{
	content:'';
	display: inline-block;
	width: 0.6em;
	height: 0.6em;
	border-radius: 50%;
	background: $laranja;
	margin-right: 10px;
	align-self: center;
}

.acessibilidade__contrast{
	align-items: center;

	.title{
		margin-right: 5px;
	}
}

@include media-breakpoint-up(xl) {
	.topo__faixa{
		.container{
			display: flex;
			white-space: nowrap;
			align-items: center;
		}

		.boas__vindas{
			flex-grow: 1;
		}
	}

	.contato__topo + .contato__topo{
		margin-left: 10px;
	}

	.acessibilidade,
	.acessibilidade__contrast{
		margin-left: 10px;
	}

	.acessibilidade__contrast{
		border-left: 1px solid currentcolor;
		padding-left: 10px;
	}

}

@include media-breakpoint-only(lg) {
	.topo__faixa{
		.container{
			display: flex;
			white-space: nowrap;
			align-items: center;
		}

		.boas__vindas{
			flex-grow: 1;
		}
	}

	.contato__topo + .contato__topo{
		margin-left: 10px;
	}

	.acessibilidade,
	.acessibilidade__contrast{
		margin-left: 10px;
	}

	.acessibilidade__contrast{
		border-left: 1px solid currentcolor;
		padding-left: 10px;
	}

	.uppercase {
		.contato__topo{
			display: none;
		}
	}

}

@include media-breakpoint-only(md) {
	.topo__faixa{
		background: var(--bgFaixaTopo, #323232);
		.container{
			display: flex;
			white-space: nowrap;
			align-items: center;
			max-width: 100%;
			flex-wrap: wrap;
		}

		.boas__vindas{
			flex-grow: 1;
		}
	}

	.contato__topo + .contato__topo{
		margin-left: 10px;
	}

	.acessibilidade,
	.acessibilidade__contrast{
		margin-left: 10px;
	}

	.acessibilidade__contrast{
		border-left: 1px solid currentcolor;
		padding-left: 10px;
	}

	.uppercase {
		.contato__topo{
			display: none;
		}
	}

}

@include media-breakpoint-only(sm) {
	.topo__faixa{
		background: var(--bgFaixaTopo, #323232);

		.contato__topo{
			display: none;
		}

		.container{
			max-width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
		}
	}

	.acessibilidade{
		justify-content: center;
	}

	.acessibilidade__contrast{
		margin-left: 10px;
		padding-left: 10px;
		border-left: 1px solid currentcolor;
	}
}

@include media-breakpoint-down(xs) {
	.topo__faixa{
		text-align: center;

		.contato__topo{
			display: none;
		}
	}

	.acessibilidade{
		justify-content: center;
		padding-top: 10px;
	}

	.acessibilidade__contrast{
		margin-left: 10px;
		padding-left: 10px;
		border-left: 1px solid currentcolor;
	}
}
