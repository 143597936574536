.mobile__controls{
	display: grid;
	grid-template-columns: 50px 1fr 50px;
	height: 70px;

	.brand{
		padding: 5px 10px;
		width: 100%;
		max-width: 214px;
		text-align: center;
		margin: 0 auto;
	}

	.btn{
		border-radius: 0;
		font-size: 20px;
		color: var(--colorBtnMobile, #232323);
		box-shadow: none !important;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}
