.sprite-svg-area{
	height: 0;
	overflow: hidden;
}

#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.wrapper{
		flex-grow: 1;
	}
}

html.uppercase{
	font-size: 20px;
}

body{
	transition: all 0.3s linear;
}


.owl-carousel:not(.owl-loaded) {
	overflow: hidden;
	white-space: nowrap;

	& > *{
		display: inline-block;
		white-space: normal;
	}

	@include media-breakpoint-up(xl) {
		--item-width: calc(100% / attr(data-xl));

		& > * {
			min-width: var(--item-width);
		}
	}
}
