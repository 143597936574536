.topo__main{
	.redes__sociais{
		a{
			display: inline-flex;
			width: 36px;
			height: 36px;
			align-items: center;
			justify-content: center;
			background: $laranja;
			color: #FFF;
			border-radius: 50%;
			font-size: 18px;
			transition: all 0.3s linear;
			margin:0 2px;

			&:hover{
				text-decoration: none;
				background: var(--bgRedesSociaisTopoHover, $cinza);
				color: #FFF;
				box-shadow: 0 0 0 5px rgba(#CCC, .8);
			}
		}
	}

	.btn-search{
		display: inline-flex;
		width: 36px;
		height: 36px;
		align-items: center;
		justify-content: center;
		background: $laranja;
		color: #FFF;
		border-radius: 50%;
		font-size: 18px;
		transition: all 0.3s linear;
		margin:0 2px;

		&:hover{
			text-decoration: none;
			background: var(--bgRedesSociaisTopoHover, $cinza);
			color: #FFF;
			box-shadow: 0 0 0 5px rgba(#CCC, .8);
		}
	}
}

body:not(.contrast) .brand{
	img.logo-contrast{
		display: none;
	}
}

.contrast .brand{
	img:not(.logo-contrast){
		display: none;
	}
}

@include media-breakpoint-up(xl) {
	.topo__main{
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
		max-width: map-get($container-max-widths, xl);
		margin-left: auto;
		margin-right: auto;

		.brand{
			padding: 15px 30px 15px 0px;
		}

		.content{
			display: grid;
		}

		.redes__sociais{
			display: flex;
		}

		.redes__sociais,
		.btn-search{
			align-self: center;
		}
	}

	html:not(.uppercase) {
		.topo__main{
			.content{
				grid-template-columns: 220px 1fr min-content min-content;
			}
		}
	}

	html.uppercase {
		.topo__main{
			.content{
				grid-template-columns: 120px min-content min-content;
			}

			.brand,
			.redes__sociais,
			.btn-search{
				grid-row: 1/2;
			}

			.brand{
				grid-column: 1/2;
			}

			.redes__sociais{
				grid-column: 2/3;
			}

			.btn-search{
				grid-column: 3/4;
			}

			.main__menu{
				grid-row: 2/3;
				grid-column: 1/4;
			}
		}
	}
}

@include media-breakpoint-only(lg) {
	.topo__main{
		.content{
			padding-left: 15px;
			padding-right: 15px;
			display: grid;
			grid-template-columns: 1fr min-content min-content;
			width: 100%;
			max-width: map-get($container-max-widths, lg);
			margin-left: auto;
			margin-right: auto;
			padding-top: 15px;
		}

		.redes__sociais{
			display: flex;
		}

		.brand,
		.redes__sociais,
		.btn-search{
			grid-row: 1/2;
		}

		.brand{
			grid-column: 1/2;
		}

		.redes__sociais{
			grid-column: 2/3;
			align-self: center;
		}

		.btn-search{
			grid-column: 3/4;
			align-self: center;
		}

		.main__menu{
			grid-row: 2/3;
			grid-column: 1/4;
		}
	}
}

@include media-breakpoint-down(md) {
	.topo__main{
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 270px;
		overflow: auto;
		z-index: 1000;
		background: var(--bgMainTopoMobile, #FFF);
		transition: all 0.6s linear;
		transform: translateX(var(--x,-100%));

		&.topo__main--shown {
			--x: 0px;
		}

		.brand{
			padding: 30px 15px;
			text-align: center;
		}

		.btn-search{
			display: none;
		}

		.redes__sociais{
			text-align: center;
			padding: 10px;
			border-top: 1px solid rgba(#CCC,.7);
		}
	}

	body.topo__main--shown{
		.topo__faixa,
		.mobile__controls,
		.wrapper,
		.rodape{
			filter: blur(4px);
			transition: filter 0.4s linear;
		}
	}
}
