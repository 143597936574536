.internas{
	padding-bottom: 60px;
}

.page-header{
	padding: 15px 0;
	background: $laranja;
	color: #FFF;
	margin-bottom: 54px;

	h1{
		font-size: 1rem;
		text-align: right;
		margin: 0;
	}
}

.content__editable{
	@include clearfix;
	margin-bottom: 40px;

	img{
		max-width: 100%;
		height: auto !important;
	}
}

.certificacoes{
	.servico{
		margin-bottom: 30px;
		text-align: center;
		a{
			color: #333333;
			transition: all 0.3s linear;
		}

		&:hover{
			.servico__title{
				color: $laranja;
			}
		}
	}

	.servico__foto{
		margin-bottom: 10px;

		lazy-image,
		img {
			border-radius: 20px;
		}
	}

	.servico__title {
		font-family: 'montserratblack';
		font-size: toRem(16);
		margin-bottom: 10px;
		@include line-clamp();
		transition: all 0.3s linear;
	}

	.servico__desc{
		font-size:  toRem(13);
		@include line-clamp();
	}


	.servico a:hover{
		text-decoration: none;
		color: #333333;

		.servico__title{
			color: $laranja;
		}
	}
}
