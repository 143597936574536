.contrast{
	background: #1f1f1f;
	color: #FFF;

	--bgBody: #1f1f1f;
	--colorBody: #FFF;

	--shadowTopo: rgba(255,255,255,.4);
	--colorBtnMobile: #FFF;
	--bgRedesSociaisTopoHover: #{darken($laranja, 10%)};
	--bgMainTopoMobile: #{$cinza};
	--bgTopo: #{map-get($cores, 'cinza-darken-1')};

	--bgFormControl: #{ map-get($cores, 'cinza-lighten-4') };
	--bgServicoProdutoThumb: #{ map-get($cores, 'cinza-lighten-3') };
	--colorServicoProdutoThumb: #000;

	--icon-favicon-bg: var(--icon-favicon--inverse-bg);
	--bgQuemSomos: #{lighten($cinza, 50%)};
	--gradientQuemSomos: linear-gradient(to bottom, #{rgba($cinza,0.8)} 0%, #{rgba($cinza,0.8)} 100%);
	--faqGradient: linear-gradient(to bottom, #{rgba($cinza,0.8)} 0%, #{rgba($cinza,0.8)} 100%);
}
