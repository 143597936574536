@import "_topo__faixa.scss";
@import "_topo__main.scss";
@import "_mobile__controls.scss";
@import "__main__menu.scss";
@import "_search.scss";

.topo{
	box-shadow: 0 0 8px var(--shadowTopo, rgba(#000,.4));
	background: var(--bgTopo, #FFF);

	&.topo--fixed{
		position: sticky;
		top: var(--offset, 0px);
		z-index: 999;
	}
}

.backdrop{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background: rgba(#000,.7);
	animation: fadeIn 0.6s linear;

	&.hide{
		animation: fadeOut 0.6s linear;
	}
}

@include media-breakpoint-down (md) {
	.dropdown-menu{
		position: relative !important;
		transform: none !important;
		margin: 0 0 !important;
		float: none !important;
		padding: 0 0 !important;
		border: none;
		margin: 15px 0px !important;
	}
}
.dropdown-menu{
	white-space: nowrap;
	text-align: right;
	box-shadow: none;
	filter: drop-shadow(0 0 2px rgba(#000,.5));
	border: none;
	padding: 0px;
	border-radius: 0px;

	color: #323232;
	background: #fff;

	li{
		&:before{
			display: none !important;
		}
	}

	a{
		color: $laranja;
		display: flex;
		text-align: center;
		padding: 10px;

		&:hover{
			color: #000;
		}
	}

	&:before{
		content:'';
		position: absolute;
		right: 8px;
		bottom: 100%;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid #FFF;
	}

	.active a,
	a:hover{
		background: #323232;
		color:  #FFF;
	}
}
