.main__menu{
	font-family: 'montserratbold', sans-serif;
	font-size: toRem(14);

	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	a:hover{
		text-decoration: none;
	}
}

@include media-breakpoint-up (lg) {
	.main__menu{
		padding-right: 30px;

		&,
		& > ul,
		& > ul > li,
		& > ul > li > a{
			display: flex;
		}

		& > ul{
			justify-content: space-between;
			width: 100%;
		}

		& > ul > li > a{
			align-items: center;
			border-top: 5px solid transparent;
			color: #353535;
		}

		& > ul > li.active > a,
		& > ul > li.show > a,
		& > ul > li.active:hover > a,
		& > ul > li.show:hover > a,
		& > ul > li:hover a{
			border-color: $laranja;
			color: $laranja;
		}
	}

	.contrast{
		.main__menu{
			& > ul > li > a{
				color: #FFF;
			}

			& > ul > li.active > a,
			& > ul > li.show > a,
			& > ul > li.active:hover > a,
			& > ul > li.show:hover > a,
			& > ul > li:hover a{
				border-color: $laranja;
				color: $laranja;
			}
		}
	}

	.uppercase{
		.main__menu{
			padding-right: 0;

			& > ul > li > a{
				border-top: none;
				border-bottom: 5px solid transparent;
				padding: 10px 0;
				margin-top: 10px;
			}

			& > ul > li.active > a,
			& > ul > li.show > a,
			& > ul > li.active:hover > a,
			& > ul > li.show:hover > a,
			& > ul > li:hover a{
				border-color: $laranja;
				color: $laranja;
			}
		}
	}
}

@include media-breakpoint-only(lg) {
	.main__menu{
		padding-right: 0;

		& > ul > li > a{
			border-top: none;
			border-bottom: 5px solid transparent;
			padding: 10px 0;
			margin-top: 10px;
		}

		& > ul > li.active > a,
		& > ul > li.show > a,
		& > ul > li.active:hover > a,
		& > ul > li.show:hover > a,
		& > ul > li:hover a{
			border-color: $laranja;
			color: $laranja;
		}
	}
}

@include media-breakpoint-down(md) {
	.main__menu{
		& > ul > li > a{
			display: block;
			padding: 10px 15px;
			color: inherit;
		}

		& > ul > li:hover a{
			background: rgba(#000,.2);
		}

		& > ul > li.active > a,
		& > ul > li.show > a,
		& > ul > li.active:hover > a,
		& > ul > li.show:hover > a{
			background: $laranja;
			color: #FFF;
		}
	}
}
