.paginacao{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.page__item {
		display: inline-block;
		padding: 0.375rem 0.75rem;
		background: $laranja;
		color:#FFF;
		border-radius: 50%;
		min-width: calc(0.75rem + 1.5em);
		line-height: 1.5;
		text-align: center;
		text-shadow: -1px 1px rgba(#000,.5);
		& + .page__item{
			margin-left: 5px;
		}
	}

	a.page__item:hover{
		text-decoration: none;
		color: var(--colorPaginacaoHover, #FFF);
		background: var(--bgPaginacaoHover, darken($laranja, 10%));
	}

	span.page__item--active{
		background: var(--bgPaginacaoActive, $cinza);
		color: var(--colorPaginacaoActive, #FFF);
	}
}

.contrast {
	--bgPaginacaoActive: #{map-get($cores, 'cinza-lighten-2')};
}
