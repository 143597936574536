.pagina__faq{}

.central__atendimento{
	text-align: center;
	margin-bottom: 50px;

	h2{
		font-family: 'montserratbold', sans-serif;
		font-size: 25px;
		color: $laranja;
		margin-bottom: 20px;
	}

	.input__group{
		position: relative;

		.form-control{
			font-family: 'montserratbold', sans-serif;
			height: 50px;
			border-width: 2px;
			border-radius: 25px;
			padding-left: 25px;
			padding-right: 50px;
		}

		.btn-faq{
			background: var(--bgBtnFAQ, $cinza);
			color: var(--colorBtnFAQ, #FFF);
			padding: 0 0;
			width: 46px;
			height: 46px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 2px;
			top: 2px;

			&:hover{
				background: var(--bgBtnHoverFAQ, darken(#FFF, 25%));
				color: var(--colorBtnHoverFAQ, $cinza);
			}
		}
	}
}

.faq__categrias {
	h2 {
		color: $laranja;
		font-size: 20px;
		font-family: 'montserratbold', sans-serif;
		text-align: center;
		margin-bottom: 30px;
	}
}

.owl__faq {
	padding-left: 50px;
	padding-right: 50px;
	perspective: 3px;
	min-height: 50px;
	margin-bottom: 50px;

	&:before{
		content:'';
		width: calc(100% - 100px);
		height: 3px;
		background: #ECECEC;
		top: 19px;
		left: 50px;
		z-index: -1;
		position: absolute;
	}

	.owl-prev,
	.owl-next{
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: $laranja !important;
		color: #FFF !important;
		outline: none !important;
		top: 19px;
		position: absolute;
		transform: translateY(-50%);

		&.disabled{
			cursor: default !important;
			background: grayscale($laranja) !important;
		}
	}

	.owl-prev{
		left:0;
	}

	.owl-next{
		right:0;
	}
}

.faq__link{
	display: block;
	text-align: center;
	font-family: 'montserratbold', sans-serif;
	padding-top: 5px;
	color: var(--colorCategoriaFAQ, inherit) !important;

	&:hover{
		text-decoration: none;
	}

	&:before{
		content:'';
		display: block;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		margin-right:auto;
		margin-left:auto;
		margin-bottom: 20px;
		border: 4px solid rgba(#FFF,.3);
		background: var(--azul, $cinza);
		transition: background 0.3s linear;
		box-shadow: 0 0 0 5px var(--bgBody, #fff);
	}

	&:hover{
		&:before{
			animation: flipCoin 0.6s linear;
		}
	}

	&.faq__category--active{
		color: var(----colorActiveCategoriaFAQ, $laranja) !important;

		&:before{
			background: $laranja;
		}
	}
}

@keyframes flipCoin{
	0%{transform: rotateY(0deg)}
	100%{transform: rotateY(180deg)}
}

.faq__item{
	margin-bottom: 10px;
	background: #EDEDED;
	color: #747474;

	.btn{
		width: 100%;
		text-align: left;
		font-family: 'montserratbold';
		box-shadow: none !important;

		&:before{
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			display: block;
			float: right;
			margin-left: 15px;
		}

		&[aria-expanded="true"]{
			color: $laranja;
		}

		&[aria-expanded="false"]:before{
			content: fa-content($fa-var-plus);
		}

		&[aria-expanded="true"]:before{
			content: fa-content($fa-var-minus);
		}
	}

	.faq__resposta{
		padding: 0 0.75rem 0.375rem;

		&:before{
			content:'';
			display: block;
			background: currentColor;
			height: 3px;
			margin-bottom: 0.375rem;
			opacity: 0.5;
		}
	}
}

.faq__resposta{
	padding: 10px 0;
}
