// Margins
@for $i from 1 through 20 {
	$m: $i * 5;

	@include margin-variant($m);
}

[data-owl]{

	&:not(.ready){
		display: none;
	}

	&.disabled{
		cursor: not-allowed;
		opacity: 0.6;
	}
}


.slick-slider:not(.slick-initialized) {
	opacity: 0;
}

.loader{
	$d: 2em;
	$t: 1.5s;
	text-align: center;
	width: 100%;

	.dot {
		background: $laranja;

		&, &:after {
			display: inline-block;
			width: $d; height: $d;
			border-radius: 50%;
			animation: dotLoader $t calc(((var(--i) + var(--o, 0))/var(--n) - 1)*#{$t}) infinite
		}

		&:after {
			--o: 1;
			background: var(--bgBody, #FFF);
			content: ''
		}
	}
}

@keyframes dotLoader { 0%, 50% { transform: scale(0) } }
