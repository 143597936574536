.form__contato{
	$verde: map-get($cores, 'green-lighten-1');

	.form-group{
		margin-bottom: 5px;
	}

	.form-control{
		background-color: var(--bgFormControl, #E5E5E5);
		border-color: transparent;
		border-radius: 0;
		min-height: 50px;
		background-image: linear-gradient(to right, var(--context, $verde) 0%, var(--context, $verde) 50%, transparent 50%, transparent 100%);
		background-size: 200% 3px;
		background-repeat: no-repeat;
		background-position: right bottom;
		transition: background 0.3s linear;

		color: #000;

		&::placeholder{
			color: rgba(#000,.5);
		}

		&:focus{
			background-position: left bottom;
			box-shadow: none;
		}
	}
	input.form-control{
		&:not(:placeholder-shown){
			background-position: left bottom;
			box-shadow: none;
		}
	}

	textarea.form-control{
		min-height: 175px;
	}

	.btn-enviar{
		transform: translateY(calc((100% + 20px) * -1));
		margin-right: 10px;
	}
}
