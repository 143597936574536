.search{
	position: fixed;
	top: var(--t, 0px);
	left: 50%;
	transform: translate(-50%, var(--y, -150%));
	transition: all 0.6s linear;
	width: calc(100% - 30px);
	max-width: 600px;
	z-index: 1000;

	text-align: center;
	color: #FFF;

	font-size: 20px;

	&.search--shown{
		--t: 80px;
		--y: 0px;
	}

}

.input__search{
	position: relative;

	.form-control{
		font-size: 20px;
		border-radius: 25px;
		padding-left: 25px;
		padding-right: 50px;
		height: 52px;
	}

	.btn{
		position: absolute;
		width: 46px;
		height: 46px;
		border-radius: 50%;
		padding: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		right: 3px;
		top: 3px;
		@include button-variant($laranja, $laranja);
	}
}

body.search--shown{
	.topo__faixa,
	.mobile__controls,
	.wrapper,
	.rodape,
	.topo__main{
		filter: blur(4px);
		transition: filter 0.4s linear;
	}
}
