@import "_creditos.scss";

.selo__custom{
	margin-bottom: 0px;
}
.selo__segura{
	max-width: 70px;
	min-width: unset;
	margin-left: 0px;
	margin-right: auto;
	margin-top: 15px;
}
.selos__segura{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;

	.selo__segura{
		margin: 10px;
	}
}
.segura__selos{
	display: flex;
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 5px;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	margin-top: 10px;

	.selo__custom{
		&:first-child{
			margin-right: 10px;
		}
	}
}

.rodape{
	background: var(--bgRodape, #353535);
	color: var(--colorRodape, #FFF);
	padding-top: 60px;
	font-size: toRem(13);

	.rodape__brand{
		margin-bottom: 25px;
	}

	.redes__sociais{
		a{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			border-radius: 50%;
			background: $laranja;
			font-size: 20px;
			color:#FFF;
			margin:0 5px;
			transition: all 0.3s linear;

			&:hover{
				text-decoration: none;
				color: #323232;
				background: #FFF;
				box-shadow: 0 0 0 5px rgba(#FFF, 0.4);
			}
		}
	}
}

.title__footer{
	font-size: toRem(15);
	color: $laranja;
	font-family: 'montserratbold', sans-serif;
	margin-bottom: 15px;
}

.footer__nav{
	list-style: none;
	padding-left: 0;
	margin-bottom: 30px;

	li + li{
		margin-top: 10px;
	}

	a{
		color: inherit;
	}
}

.bloco__newsletter{
	.btn{
		border-radius: 0;
		@include button-variant($laranja, $laranja);
		color: #FFF !important;

		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}

	.form-control{
		border-radius: 0;
	}
}

.contatos__rodape{
	padding-top: 30px;
	border-top: 1px solid #FFF;
	margin-bottom: 15px;

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 30px;
	}
}

.contato__rodape{
	display: flex;
	margin-bottom: 30px;

	.content__area{
		padding-left: 15px;
	}
}

@include media-breakpoint-up(xl) {
	.wrapper__rodape{
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 30px;
	}
}

@include media-breakpoint-only(lg) {
	.wrapper__rodape{
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 30px;
	}

	.rodape__qmsoms{
		grid-column: span 3;
		text-align: center;
	}
}

@include media-breakpoint-down(sm) {
	.rodape{
		.redes__sociais{
			text-align: center;
		}
	}
}
