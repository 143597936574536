#qmSomos{
	padding-bottom: 60px;
	font-size: toRem(14);

	@include media-breakpoint-up(md) {
		background-attachment: fixed;
		background-color: var(--bgQuemSomos, #FFF);
		background-image: var(--gradientQuemSomos, linear-gradient(to bottom, transparent, transparent)) , url('../images/bg-qmSomos.png');
		background-repeat: no-repeat;
		background-position: center right;
		background-blend-mode: multiply;
	}

	.container:before{
		content:'';
		display: block;
		border-top: 5px solid $laranja;
		margin-bottom: 50px;
	}

	.btn{
		border-radius: 0;
		padding: 0.5rem 1.5rem;
		font-family: 'montserratbold', sans-serif;
	}

	hgroup{
		text-align: center;
		margin-bottom: 20px;

		h1{
			color: $laranja;
			font-size: toRem(14);
			font-family: 'montserratbold', sans-serif;
		}

		h2{
			font-family: 'montserratblack', sans-serif;
			color: var(--colorBody, #353535);

			&:after{
				content:'';
				background-image: url('../images/sprites.png');
				background-repeat:no-repeat;
				display: block;
				margin: 10px auto;
				background-position: var(--icon-favicon-bg);
				width: var(--icon-favicon-width);
				height: var(--icon-favicon-height);
			}
		}
	}
}

#servicosProdutos{
	overflow: hidden;

	@include media-breakpoint-up(xl) {
		.servicosProdutos__inner{
			width: percentage(1920/1170);
			max-width: 1920px;
			position: relative;
			perspective: 3px;
			transform: translateX(-50%);
			left: 50%;

			min-height: 80px;
			display: flex;

			&:before{
				content:'';
				position: absolute;
				width: 100%;
				height: calc(100% - 225px);
				background: $cinza;
				top: 0;
				left: 0;
				z-index: -1;
			}

			.image__area{
				width: 650px;
			}

			.content__area{
				flex-grow: 1;
			}
		}
	}

	.image__area{
		background-image: var(--lazy-bg, none);
		background-color: transparent;
		background-size: cover;

		&:before{
			content: '';
			display: block;
			padding-bottom: percentage(642/649);
		}
	}

	.content__area{
		position: relative;
		perspective: 3px;
		min-height: 250px;
		padding: 50px 15px;
		@include media-breakpoint-up(xl) {
			max-width: (map-get($container-max-widths, xl) - 240px);
		}

		@include media-breakpoint-down(lg) {
			width: 100%;
			max-width: map-get($container-max-widths, lg);
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}

		@include media-breakpoint-only(lg) {
			position: relative;
			perspective: 3;

			&:before{
				content:'';
				width: 100vw;
				height: calc(100% - 255px);
				background: $cinza;
				z-index: -1;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		@include media-breakpoint-down(md) {
			position: relative;
			perspective: 3px;
			overflow: hidden;

			.servicos__produtos:before{
				content:'';
				width: 100vw;
				height: 500vh;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				z-index: -1;
				background: $cinza;
				bottom: 50%;
			}
		}
	}

	hgroup{
		margin-bottom: 20px;

		h1{
			color: $laranja;
			font-size: toRem(14);
			font-family: 'montserratbold', sans-serif;
		}

		h2{
			font-family: 'montserratblack', sans-serif;
			color: #FFF;

			&:after{
				content:'';
				background-image: url('../images/sprites.png');
				background-repeat:no-repeat;
				display: block;
				margin: 10px 0;
				background-position: var(--icon-favicon--inverse-bg);
				width: var(--icon-favicon--inverse-width);
				height: var(--icon-favicon--inverse-height);
			}
		}

		@include media-breakpoint-down(lg) {
			text-align: center;

			h2:after{
				margin: 10px auto;
			}
		}
	}

	.btn{
		padding: 0.5rem 1.5rem;
		border-radius: 0;
		font-family: 'montserratbold', sans-serif;
	}

	.divider{
		height: 5px;
		background: #eee;
		margin-bottom: 40px;

		&:before{
			content: '';
			width: percentage(83/800);
			margin-left: 10%;
			height: 5px;
			display: block;
			background: $laranja;
		}
	}

	.content__editable{
		color: #FFF;
	}
}

.file__group{
	display: flex;
	flex-direction: column;
	margin-bottom: 9px;

	label{
		margin-bottom: 5px;
	}
}

#faq{
	padding-top: 55px;
	padding-bottom: 25px;
	perspective: 3px;
	@include media-breakpoint-up(lg) {
		&:before{
			content: '';
			display: block;
			position: absolute;
			width: 100vw;
			min-height: 75%;
			background: var(--petalas, url(../images/petalas-index.png)) left center;
			z-index: -1;
			background-repeat: no-repeat;
			top: 25%;
			left: 0;
			mix-blend-mode: multiply;
			background-attachment: fixed;
		}
	}

	hgroup{
		margin-bottom: 20px;

		h1{
			color: $laranja;
			font-size: toRem(14);
			font-family: 'montserratbold', sans-serif;
		}

		h2{
			font-family: 'montserratblack', sans-serif;
			color: var(--colorBody, #353535);

			&:after{
				content:'';
				background-image: url('../images/sprites.png');
				background-repeat:no-repeat;
				display: block;
				margin: 10px 0;
				background-position: var(--icon-favicon-bg);
				width: var(--icon-favicon-width);
				height: var(--icon-favicon-height);
			}
		}
	}

	.container{
		perspective: 1000px;
	}

	.btn{
		padding: 0.5rem 1.5rem;
		border-radius: 0;
		font-family: 'montserratbold', sans-serif;
	}

	background-image: var(--faqGradient, linear-gradient(to bottom, transparent 0%, transparent 100%)), var(--lazy-bg);
	background-blend-mode: multiply, multiply;
}

.areas__index{
	overflow: hidden;
	position: relative;
}

.blog{
	position: relative;

	iframe{
		position: relative;
		overflow: hidden;
		height: 3800px;

		@include media-breakpoint-down (md) {
			height: 3800px;
		}
	}
}

#clientes{
	padding-bottom: 50px;
	position: relative;

	@include media-breakpoint-up(lg) {
		&:before{
			content: '';
			display: block;
			position: absolute;
			width: 100vw;
			min-height: 100%;
			background: var(--petalas, url(../images/petalas-index.png)) left center;
			z-index: -1;
			background-repeat: no-repeat;
			top: 0;
			left: 0;
			mix-blend-mode: multiply;
			background-attachment: fixed;
		}
	}

	& > *{
		position: relative;
		z-index: 5;
	}

	.container:before{
		content:'';
		display: block;
		border-top: 5px solid $laranja;
		margin-bottom: 50px;
	}

	h1{
		font-family: 'montserratbold', sans-serif;
		font-size: toRem(14);
		text-align: center;
		margin-bottom: 20px;
		color: $laranja;
	}
}

.owl__clientes{
	padding-left: 25px;
	padding-right: 25px;

	.owl-stage{

		&,
		.owl-item,
		.cliente,
		.cliente a{
			display: flex;
		}

		.owl-item{
			padding: 10px;
		}
	}
	.owl-prev,
	.owl-next{
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: $laranja !important;
		color: #FFF !important;
		outline: none !important;
		top: calc(50% - 40px);
		position: absolute;

		&.disabled{
			cursor: default !important;
			background: grayscale($laranja) !important;
		}
	}

	.owl-prev{
		left:0;
	}

	.owl-next{
		right:0;
	}

	.cliente{
		box-shadow: 0 0 4px rgba(#000,.4);
		background: #FFF;
		padding: 10px;
		min-height: 120px;
		transition: all 0.4s linear;
		animation-fill-mode: none !important;

		&,
		a{
			display: flex;
		}

		a{
			width: 100%;
			align-items: center;
			justify-content: center;
		}

		&:hover{
			position: relative;
			z-index: 3;
			box-shadow: 0 0 10px rgba(#000,.5);
		}
	}
}
